<template>
  <div
    v-if="modelValue"
    ref="actionBar"
    class="fixed inset-x-0 bottom-0 z-30 flex h-[72px] w-full flex-row items-center justify-between rounded-t-3xl bg-black-90 px-16 text-white"
  >
    <small v-if="title">
      {{ title }}
    </small>
    <div class="flex flex-1 items-center justify-center">
      <slot />
    </div>
    <div class="flex flex-row gap-2">
      <UiButtonBase
        v-if="secondaryButtonText"
        id="popup_secondary"
        type="secondary"
        :disabled="loading"
        class="w-auto !border-black-20 !text-primary-20 hover:!border-white hover:!bg-transparent hover:!text-white"
        @click="cancel"
      >
        {{ secondaryButtonText }}
      </UiButtonBase>
      <slot name="primaryButton">
        <UiButtonBase v-if="primaryButtonText" id="popup_primary" class="w-auto" :disabled="loading" @click="confirm">{{
          primaryButtonText
        }}</UiButtonBase>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const modelValue = defineModel<boolean>()

const emits = defineEmits(['confirm', 'cancel'])

const route = useRoute()

watch(
  () => route.name,
  () => {
    modelValue.value = false
  }
)

type Props = {
  title?: string
  primaryButtonText?: string
  secondaryButtonText?: string
  loading?: boolean
}

defineProps<Props>()

const actionBar = ref<HTMLElement | null>(null)

const cancel = () => {
  emits('cancel')
  modelValue.value = false
}

const confirm = () => {
  emits('confirm')
}
</script>

<style scoped></style>
